/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

interface CreateDemoProps { }

const CreateDemo: React.FC<CreateDemoProps> = () => {
  const [productModuleKey, setProductModuleKey] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [demoList, setDemoList] = useState<any[]>([]);
  const [selectedStack, setSelectedStack] = useState('');

  const stacks = [
    { stack: 'za', name: 'South Africa' },
    { stack: 'uk', name: 'United Kingdom' },
  ];
  const themes = ['dierenkliniek', 'londonvetclinic'];

  useEffect(() => {
    // Make the API call to fetch the `embedUrl` from "/token" using the `email` parameter
    fetch(`/api/list`)
      .then(response => response.json())
      .then(data => {
        console.log('List fetched:', data);
        setDemoList(data.list);
      })
      .catch(error => {
        console.error('Error fetching list:', error);
        setDemoList([{ "demoPath": "test-path--abcde", "created": "2023-08-02T16:28:12.733Z", "stack": "za" }]);
      });
  }, []);

  const handleProductModuleKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProductModuleKey(event.target.value);
  };

  const handleApiKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApiKey(event.target.value);
  };

  const handleStackChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStack(event.target.value);
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Check the length of productModuleKey and apiKey
    if (productModuleKey.length < 3 || apiKey.length < 10) {
      alert('Product Module Key must be at least 3 characters and API Key must be valid.');
      return;
    }
    if (selectedStack === '') {
      alert('Select a stack');
      return;
    }

    // Construct the query parameters
    const queryParams = new URLSearchParams({
      product_module_key: productModuleKey,
      root_api_key: apiKey,
      stack: selectedStack,
    });

    // Make the form post to /api/new with the query parameters
    const response = await fetch(`/api/new?${queryParams}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const result = await response.text();
    window.location.href = result;
  };

  return (
    <section className='create-demo'>
      <h3>Create New Demo</h3>
      <hr />
      <p>Welcome to the demo creation page!</p>
      <p>Please enter the required information to set up a new demo. To set up a demo, you are required to create an API keys in the org.</p>
      <p>You can set up a demo for any product or organisation.</p>
      <form onSubmit={handleFormSubmit}>
        <p>
          <input
            type='text'
            placeholder='Product Module Key (e.g. nn_petx)'
            value={productModuleKey}
            onChange={handleProductModuleKeyChange}
          />
        </p>
        <p>
          <input
            type='text'
            placeholder='API Key (full access)'
            value={apiKey}
            onChange={handleApiKeyChange}
          />
        </p>
        <p>
          <select value={selectedStack} onChange={handleStackChange}>
            <option value="">Select Stack</option>
            {stacks.map((s, index) => (
              <option key={index} value={s.stack}>{s.name}</option>
            ))}
          </select>
        </p>
        <p>
          <button type='submit' className='button'>
            Create demo
          </button>
        </p>
      </form>

      <br />


      <h3>Demos</h3>
      <hr />
      <table>
        <tr><th>Key</th><th>Product Module</th><th>Stack</th><th>Demo</th></tr>
        {demoList.map(d => <tr key={d.demoPath}>
          <td>{d.demoPath.split('--')[1]}</td>
          <td>{d.demoPath.split('--')[0]}</td>
          <td>{stacks.find(s => s.stack === d.stack)?.name}</td>
          <td>{themes.map(t => <a key={t} href={`/${d.stack}/${t}/${d.demoPath}`} className='theme-option'>{t}</a>)}</td>
        </tr>)}
      </table>

    </section>
  );
};

export default CreateDemo;
