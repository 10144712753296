/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

const Login = () => {
  const [email, setEmail] = useState('');

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const redirectToAccount = () => {
    const currentPath = window.location.pathname; // Get the current path
    const newPath = currentPath.replace('/login', '/account'); // Replace /login with /account
    const encodedEmail = encodeURIComponent(email);
    window.location.href = `${newPath}?email=${encodedEmail}`;
  };

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    redirectToAccount();
  };

  return <section className='login'>
    <h3>Login</h3>
    <hr />
    <p>Welcome back!</p>
    <p>Please enter your email to proceed to your account.</p>
    <form onSubmit={handleFormSubmit}>
      <input
        type='email'
        placeholder='Enter your email'
        value={email}
        onChange={handleEmailChange}
      />
      <p>
        <button type='submit' className='button'>
          Continue to account
        </button>
      </p>
    </form>
  </section>;
};

export default Login;
