import React, { useEffect, useState } from 'react';
import { getDemoPath } from '../utils/path-helpers';

const Insurance = () => {
  const [embedUrl, setEmbedUrl] = useState('');

  useEffect(() => {
    const demoPath = getDemoPath();

    // Make the API call to fetch the `embedUrl` from "/token" using the `email` parameter
    fetch(`/api/issuing?path=${demoPath}`)
      .then(response => response.json())
      .then(data => {
        setEmbedUrl(data.embedSalesUrl);
        console.log('Fetched embed URL:', data.embedSalesUrl);
      })
      .catch(error => {
        console.error('Error fetching embedUrl:', error);
      });
  }, []);

  return embedUrl ? <iframe title='Root Embed' src={embedUrl}></iframe> : null;
}

export default Insurance;
