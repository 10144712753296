import React from 'react';
import Navigation from '../components/navigation';
import { Outlet } from 'react-router-dom';
import { getTheme } from '../utils/path-helpers';

const ThemeLayout = () => {
  const theme = getTheme();

  return <div className={`theme-${theme}`}>
    <Navigation />
    <Outlet />
  </div>;
}

export default ThemeLayout;
