import React, { useEffect, useState } from 'react';
import { getDemoPath } from '../utils/path-helpers';

const Account = () => {
  const [embedUrl, setEmbedUrl] = useState('');

  useEffect(() => {
    // Fetch the `email` query parameter from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get('email');

    const demoPath = getDemoPath();

    // Make the API call to fetch the `embedUrl` from "/token" using the `email` parameter
    fetch(`/api/token?email=${email}&path=${demoPath}`)
      .then(response => response.json())
      .then(data => {
        console.log('Embed URL fetched:', data);
        setEmbedUrl(data);
      })
      .catch(error => {
        console.error('Error fetching embedUrl:', error);
      });
  }, []);

  return embedUrl ? <iframe title='Root Embed' src={embedUrl}></iframe> : null;
}

export default Account;
