export const getTheme = (): string => {
  const url = new URL(window.location.href);
  const theme = url.pathname.split('/')[2] || 'dierenkliniek';
  return theme;
};

export const getDemoPath = (): string => {
  const url = new URL(window.location.href);
  const demoPath = url.pathname.split('/')[3] || 'nn_petx--e5naw';
  return demoPath;
};

export const getStack = (): string => {
  const url = new URL(window.location.href);
  const stack = url.pathname.split('/')[1] || 'za';
  return stack;
};
