/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { getDemoPath, getStack, getTheme } from '../utils/path-helpers';

const Navigation = () => {
  const stack = getStack();
  const theme = getTheme();
  const demoPath = getDemoPath();

  return <header>
    <div className='nav-items'>
      <a href={`/${stack}/${theme}/${demoPath}`}>Home</a>
      <a href={`/${stack}/${theme}/${demoPath}/insurance`}>Insurance</a>
      <a href={`/${stack}/${theme}/${demoPath}/login`}>Login</a>
    </div>
  </header>;
};

export default Navigation;
