import * as React from "react";
// import { useEffect, useState, ReactNode, ReactElement } from 'react';
import * as ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";

import Home from './pages/home';
import Insurance from './pages/insurance';
import Login from './pages/login';
import Account from './pages/account';
import CreateDemo from './pages/create-demo';
import ThemeLayout from './pages/layout-theme';

ReactDOM.createRoot(document.getElementById("root")!).render(
  <BrowserRouter>
    <Routes>
      <Route index element={<CreateDemo />} />
      <Route path='/:stack/:theme/:demoPath/*' element={<ThemeLayout />}>
        <Route index element={<Home />} />
        <Route path='insurance' element={<Insurance />} />
        <Route path='login' element={<Login />} />
        <Route path='account' element={<Account/>} />
      </Route>
    </Routes>
  </BrowserRouter>,
);
